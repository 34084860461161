#skills {
    color: var(--secondaryTextColor);
    background-color: var(--secondaryColor);
    font-family: 'M PLUS 1p', sans-serif;
    height: 100%;
}

.skills-table {
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.202);
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.2), 0 5px 15px 0 rgba(0,0,0,0.08);
    width: 95%;
    border-radius:6px;
}

.skills-row {
    border-radius:6px;
    outline: thin solid #fefcbf0c;
    text-align: center;
    height: 3em;
}
.skills-row:hover {
    background-color: #55aca942;
}

.header-row {
    background-color: #122c91;
    color: white;
    text-align: center;
    height: 3em ;
    font-family: 'Trispace', sans-serif;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
}

.header-title {
    background-color: #122c91;
}

.skills-clickable {
    color: black;
    background-color: var(--mainTextBlockColor);
    border: 1px rgba(255, 255, 255, 0.254) solid;
    border-radius: 1em;
    font-size: 1em;
    font-weight: bold;
    padding: 0.4em 0.3em;
}

.skills-clickable:hover {
    background-color: rgba(196, 196, 196, 1);
}

.skills-clickable:focus, .group-skill-clickable:focus{
    color: var(--mainColor);
}

.skills-exp {
    font-size: 1em;
}

.group-skills-section {

}

.skill-title {
    margin: 0 auto;
    width: 100%;
}

.group-skills {
    display: flex;
    margin: 1em 0.5em;
    padding-top: 1.5em;
    flex-wrap: wrap;
    justify-content: center;
}

.group-skill-clickable {
    font-size: 1.15em;
    margin: 0.2em 0.15em;
}

.skills-certificate {
    width: 40%;
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .skills-table {
        width: 40%;
    }

    .group-skills {
        margin: 1em auto;
        width: 40%;
        justify-content: center;
    }
}

@media only screen and (min-width: 1921px) {
    .skills-table {
        width: 40%;
    }

    .skills-row {
        height: 5em;
    }

    .group-skills {
        margin: 1em auto;
        width: 40%;
        justify-content: center;
    }

    .skills-clickable {
        font-size: 1.3em;
    }

    .group-skill-clickable {
        font-size: 1.3em;
        margin: 0.2em 0.15em;
    }

    .skills-exp {
        font-size: 1.3em;
    }

    .skills-certificate {
        font-size: 1.3em;
        padding: 1em;
    }
}