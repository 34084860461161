#projects {
    color: var(--secondaryTextColor);
    background-color: var(--secondaryColor);
    font-family: 'M PLUS 1p', sans-serif;
    height: 100%;
}

.project-cards{
    margin: -1em 0;
}

.project-card {
    background-color: transparent;
    border: 0.1px black solid;
    border-radius: 1em;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.2), 0 5px 15px 0 rgba(0,0,0,0.08);
    margin: 1em 1em;
    transition: all .4s ease;
    cursor: pointer;
}

.project-card:hover {
    color: var(--mainTextColor);
    font-weight: bold;
    background-color: #fefcbfd2;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.2), 0 5px 15px 0 rgba(0,0,0,0.5);
}

.project-name {
    font-size: 1.2em;
    padding-top: 1em;
    text-align: center;
    text-transform: uppercase;
}

.project-subtitle {
    font-size: 0.75em;
    text-align: center;
}

.project-mainpreview-image {
    border: black solid 1px;
    display: block;
    margin: 1em auto;
    width: 90%;
}

.project-date {
    font-size: 0.75em;
    font-style: italic;
    font-weight: 500;
    text-align: center;
}

.project-description {
    font-size: 0.75em;
    margin: 0;
    text-align: center;
}

.project-modal-outer {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    position: fixed; /* Stay in place */
    display: grid;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    z-index: 1000; /* Sit on top */
}

.project-modal-card {
    background-color: var(--mainColor);
    border-radius: 10px;
}

.project-modal-title {
    color: black;
    font-weight: 100;
    text-align: center;
    text-transform: uppercase;
}

.project-modal-subtitle {
    font-size: 0.9em;
    font-weight: 900;
    text-align: center;
}

.project-modal-date {
    font-size: 0.8em;
    font-style: italic;
    text-align: center;
}

.project-carousel{
    display: flex;
    margin: 2em 0;
}

.carousel-img {
    width: 100%;
}

.project-modal-description {
    color: black;
    font-size: 0.9em;
    text-align: justify;
}

.project-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.link-block {
    background-color: #2a6fdb;
    border: black solid 1px;
    margin: 0.5em auto;
    padding: 0.4em;
    text-align: center;
    width: 40%;
}

.link-element-logo {
    margin: 0.1em;
    width: 1.4em;
}

.link-name {
    color: #122c91;
    font-size: 1.1em;
    margin: 1em 0.4em;
}

.link-name:focus {
    color: #48d6d2;
}

.project-tech-stack-blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 0.2em;
}

.project-tech {
    background-color: #1646e2aa;
    border: 1px black solid;
    border-radius: 10px;
    color: var(--mainTextColor);
    font-size: 0.9em;
    font-weight: 900;
    margin: 0.2em 0.2em;
    padding: 0.5em;
    text-align: center;
}

.project-tech:focus {
    color: #122c91;
}

.project-modal-close-button {
    border-radius: 8px;
    background-color: #122c91;
    color: white;
    font-size: 1em;
    padding: 0.4em;
    text-align: center;
    margin: auto;
    margin-top: 3em;
    width: 100%;
}
.project-modal-close-button:hover {
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    .project-cards{
        display: flex;
        flex-wrap: wrap;
    }

    .project-card {
        width: 30%;
        margin: 1.5em auto;
    }

    .project-mainpreview-image {
        margin: 1em auto;
    }
}

@media only screen and (min-width: 1921px) {
    .project-name {
        font-size: 2em;
    }
    .project-subtitle {
        font-size: 1.2em;
    }
    .project-date {
        font-size: 1.2em;
    }
    .project-modal-title {
        font-size: 3em;
    }
    .project-modal-subtitle {
        font-size: 1.5em;
    }
    .project-modal-date {
        font-size: 1em;
    }
    .project-modal-description {
        font-size: 1.1em;
    }
    .link-name {
        font-size: 1.4em;
    }
    .project-tech {
        font-size: 1.2em;
    }
    .project-modal-close-button {
        font-size: 1.4em;
        padding: 0.4em;
        text-align: center;
        margin: auto;
        margin-top: 3em;
        width: 100%;
    }
}