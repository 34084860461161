#about {
    color: var(--secondaryTextColor);
    background-color: var(--secondaryColor);
    font-family: 'M PLUS 1p', sans-serif;
    height: 100%;
}

.about-portrait {
    background-color: var(--mainTextBlockColor);
    border-radius: 100%;
    border: 0.1px black solid;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.2), 0 5px 15px 0 rgba(0,0,0,0.08);
    position: relative;
    margin: 0em auto;
    padding: 0.1em;
    height: 14em;
    width: 14em;
    display: flex;
    justify-content: center;
    text-align: center;
    z-index: 1;
}

.about-name {
    font-family: 'Trispace', sans-serif;
    font-size: 2em;
    text-align: center;
    text-transform: uppercase;
}

.about-description-box {
    background-color: var(--mainTextBlockColor);
    border: 0.1px black solid;
    border-radius: 1em;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.2), 0 5px 15px 0 rgba(0,0,0,0.08);
    margin: 0 5%;
    margin-top: 5em;
    transition: all .4s ease;
}

.about-description-box:hover {
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.2), 0 5px 15px 0 rgba(0,0,0,0.5);
}

.about-description {
    color: var(--mainTextColor);
    font-size: 0.8em;
    margin: 3% 8%;
    text-align: justify;
}

/* Desktop */
@media only screen and (min-width: 768px) {
    .about-portrait {
        font-size: 2em;
        padding: 0.1em;
        margin-top: 4em;
        height: 8em;
        width: 8em;
    }

    .about-description-box {
        margin: 0 15%;
        margin-top: 2em;
    }

    .about-description {
        font-size: 1.1em;
        margin: 2% 5%;
    }
}

@media only screen and (min-width: 1921px) {
    .about-portrait {
        margin-top: 7em;
        height: 14em;
        width: 14em;
    }

    .about-description-box {
        margin: 0 30%;
        margin-top: 5em;
    }
}
