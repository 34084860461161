
.nav-bar {
    position: fixed;
}

.top-navbar {
    align-items: center;
    background-color: var(--tertiaryColor);
    opacity: 90%;
    display: flex;
    flex-direction: column;
    font-family: 'Trispace', sans-serif;
    justify-content: center;
    font-size: 1em;
    height: 100%;
    width: 100%;
    padding: 0 2rem;
    position: fixed;
    margin: 0;
    top: 0;
    left: 0;
    text-transform: uppercase;
    transition: 1s;
    transform: translateY(-100vh);
    z-index: 1000;
}

.bars-icon {
    height: 1.5em;
    width: 1.3em;
    visibility: 1;
}

.navbar-logo {
    height: 5em;
    width: 5em;
}

.navbar-item {
    font-family: 'Trispace', sans-serif;
    font-weight: bold;
}

.hidden {
    display: none;

}

.responsive-nav-bar {
    transform: none;
}

nav a {
    padding-top: 2em;
    margin: 0 2rem;
    color: var(--secondaryTextColor);
}

nav a:hover {
    color: var(--secondaryTextColor);
}

.nav-btn {
    background: transparent;
    position: fixed;
    right: 0.5em;
    top: 0.5em;
    border: none;
    color: var(--mainTextColor);
    cursor: pointer;
    opacity: 1;
    outline: none;
    visibility: visible;
    font-size: 1.8rem;
    animation: slide-in-right 1s;
}

@keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(110%);
              transform: translateX(110%);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }

.nav-close-btn {
    z-index: 999;
}

/* Desktop */
@media only screen and (min-width: 1045px) {
    .top-navbar {
        align-items: center;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        font-size: 1.2em;
        font-weight: 550;
        gap: 1.5rem;
        padding-bottom: 1.7em;
        justify-content: center;
        transform: none;
        height: 10%;
        animation: slide-in-top 1.5s;
    }

    .navbar-logo {
        height: 3em;
        width: 3em;
    }

    .active-top-navbar {
        background-color: var(--tertiaryColor);
        color: white;
        opacity: 95%;
    }

    @keyframes slide-in-top {
        0% {
          -webkit-transform: translateY(-100px);
                  transform: translateY(-100px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }

    .nav-item {
        padding: 0;
    }

    nav a {
        color: inherit;
    }

    nav a:hover {
        color: inherit;
    }

    .nav-btn {
        opacity: 0;
        visibility: hidden;
    }
}