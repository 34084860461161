.header {
    background-color: var(--mainColor);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
}

.name-title {
    font-family: 'Trispace', sans-serif;
    font-size: 3.3em;
    padding-top: 1.5em;
    text-align: center;
}

.role-subtitle{
    font-family: 'Trispace', sans-serif;
    font-size: 0.8em;
    letter-spacing: 0.001em;
    text-align: center;
}

.role {
    font-family: 'Trispace', sans-serif;
}

.wave {
    display: flex;
    margin-bottom: -0.5em;
    margin-top: auto;
}

.arrow-button{
    animation: bounce 2s ease infinite;
    justify-content: center;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0);
    background-image: url('../img/arrow.png');
    background-size: contain;
    width: 4em;
    height: 4em;
    border-radius: 100%;
}

.arrow-button-pressed {
    animation: fadeaway 1.5s ease;
    animation-fill-mode: forwards;
    justify-content: center;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0);
    background-image: url('../img/arrow.png');
    background-size: contain;
    width: 4em;
    height: 4em;
    border-radius: 100%;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-30px);}
    60% {transform: translateY(-15px);}
}


@keyframes fadeaway {
    to {
        transform: scale(3);
        opacity: 0;
      }
}

.bottom-header {
    background-color: var(--secondaryColor);
    height: 12em;
    margin-bottom: -0.01em;
}

/* Desktop */
@media only screen and (min-width: 768px) {
    .name-title {
        font-size: 5em;
    }

    .role-subtitle{
        font-size: 1.3em;
    }

    .arrow-button{
        animation: none;
        width: 6em;
        height: 6em;
    }

    .arrow-button-pressed {
        width: 6em;
        height: 6em;
    }

    .arrow-button:hover {
        animation: bounce 2s ease infinite;
        background-color: rgba(129, 233, 230, 0.4);
        background-blend-mode: multiply;
    }
}

@media only screen and (min-width: 1921px) {
    .name-title {
        font-size: 7em;
        padding-top: 2em;
    }

    .role-subtitle{
        font-size: 1.6em;
    }

    .arrow-button{
        animation: none;
        width: 8em;
        height: 8em;
    }
}
