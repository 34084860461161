#experience {
    color: var(--secondaryTextColor);
    background-color: var(--secondaryColor);
    font-family: 'M PLUS 1p', sans-serif;
    height: 100%;
}

.download-button {
    text-align: center;
    display: flex;
    padding-bottom: 1.8em;
}

.download-text {
    background-color: var(--mainColor);
    border-radius: 10px;
    color: var(--mainTextColor);
    font-family: 'Trispace', sans-serif;
    font-weight: bold;
    font-size: 0.8em;
    margin: 0 auto;
    padding: 0.5em 8em;
}

.download-text:hover {
    background-color: #c9c799;
}

.downloaded-text {
    font-size: 1.5em;
    margin: 0 auto;
}

.download-text:link, .download-text:visited  {
    color: var(--mainTextColor);
}

.experience-container {
    padding: 2em 0;
}

.experience-item {
    background-color: transparent;
    border: 0.1px black solid;
    border-radius: 1em;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.2), 0 5px 15px 0 rgba(0,0,0,0.08);
    margin: 1em 1em;
    transition: all .4s ease;
}

.experience-item:hover {
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.2), 0 5px 15px 0 rgba(0,0,0,0.5);
}

.experience-logo-title-div {
    display: flex;
    margin: 0 auto;
    margin-left: -4em;
    justify-content: center;
}

.experience-logo{
    display: flex;
    height: 3em;
    margin: 0 auto;
    margin-top: 1em;
    margin-bottom: -1em;
    text-align: center;
}

.experience-company {
    font-size: 1.3em;
    padding-top: 1em;
    text-align: center;
}

.experience-role {
    color: var(--mainColor);
    font-size: 0.9em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 100;
    margin-top: -0.8em;
    text-align: center;
}

.experience-type {
    color: var(--mainColor);
    font-size: 0.8em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 100;
    margin-top: -1.5em;
    text-align: center;
}

.experience-location {
    font-size: 0.65em;
    font-style: italic;
    margin-top: -1em;
    text-align: center;
}

.experience-date {
    font-size: 0.65em;
    font-style: italic;
    margin-top: -1.5em;
    text-align: center;
}

.experience-responsibilities {
    font-size: 0.8em;
    text-align: left;
    list-style-position: outside;
    list-style: circle;
}

.experience-techstack-blocks {
    display: flex;
    margin: 1em 0em;
    padding-top: 1.5em;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

.experience-tech {
    background-color: rgba(255, 255, 255, 0.555);
    border-radius: 10px;
    border: 1px rgba(255, 255, 255, 0.254) solid;
    color: var(--mainTextColor);
    font-size: 0.7em;
    font-weight: 900;
    margin: 0 0.2em;
    padding: 0.5em;
    text-align: center;
}
.experience-tech:hover {
    background-color: rgba(196, 196, 196, 1);
}
.experience-tech:focus {
    color: var(--mainColor);
}
.experience-desription {
    font-size: 0.8em;
    margin: 1em;
    text-align: justify;
}

@media only screen and (min-width: 768px) {
    .experience-container {
        display: flex;
        flex-direction: column;
    }
    .experience-item {
        width: 40%;
        margin: 1em auto;
    }
    .experience-logo{
        height: 5.5em;
    }
    .experience-company {
        font-size: 1.8em;
    }
    .experience-role {
        font-size: 1.2em;
    }
    .experience-type {
        font-size: 1.1em;
    }
    .experience-location {
        font-size: 0.9em;
        margin-bottom: 1.2em;
    }
    .experience-date {
        font-size: 0.9em;
    }
    .experience-responsibilities {
        font-size: 1.1em;
    }
    .experience-desription {
        font-size: 1.1em;
    }
    .experience-tech {
        font-size: 1em;
        font-weight: 900;
        margin: 0 0.2em;
        padding: 0.5em;
        text-align: center;
    }
}

@media only screen and (min-width: 1921px) {
    .download-text {
        font-size: 1.1em;
        margin: 0 auto;
        padding: 0.5em 8em;
    }
    .experience-item {
        width: 50%;
        margin: 1em auto;
    }
    .experience-logo{
        height: 8em;
    }
    .experience-company {
        font-size: 2em;
    }
    .experience-role {
        font-size: 1.5em;
    }
    .experience-type {
        font-size: 1.2em;
    }
    .experience-location {
        font-size: 1.1em;
        margin-bottom: 1.2em;
    }
    .experience-date {
        font-size: 1.1em;
    }
    .experience-responsibilities {
        font-size: 1.5em;
    }
    .experience-desription {
        font-size: 1.1em;
    }
    .experience-tech {
        font-size: 1.2em;
        font-weight: 900;
        font-size: 1.4em;
    }
}