#contact {
    color: var(--secondaryTextColor);
    background-color: var(--secondaryColor);
    font-family: 'M PLUS 1p', sans-serif;
    height: 100%;
}

.contact-message {
    text-align: center;
}

.contact-form {
    display: flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.input-row {
    display: flex;
    padding: 0.5em 1em;
    justify-content: center;
    width: 100%;
}

.input-box {
    background-color: var(--mainColor);
    border: 1px solid black;
    border-radius: 6px;
    font-size: 1.1em;
    padding: 0.5em 1em;
    width: 90%;
}

.input-text-area {
    background-color: var(--mainColor);
    border: 1px solid black;
    border-radius: 6px;
    font-size: 1.1em;
    padding: 0.5em 1em;
    width: 90%;
}

.contact-submit-button {
    background-color: #122c91;
    border: 1px solid black;
    border-radius: 10px;
    color: white;
    margin-top: 2em;
    padding: 0.5em;
    width: 6em;
    cursor: pointer;
    letter-spacing: 1px;
}

.contact-links {
    background-color: var(--mainColor);
    height: 5em;
    display: flex;
    justify-content: center;
}

.contact-link {
    text-align: center;
}

.contact-link-logo {
    height: 3em;
    margin: 0 2em;
}

.acknowledgement-section {
    background-color: var(--mainColor);
    color: black;
    height: 2em;
}

.acknowledgement-text {
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .input-box {
        font-size: 1.1em;
        padding: 0.5em 1em;
        width: 30%;
    }
    .input-text-area {
        font-size: 1.1em;
        padding: 0.5em 1em;
        width: 30%;
    }
}
@media only screen and (min-width: 1921px) {
    .contact-message {
        font-size: 1.4em;
    }
    .input-box {
        font-size: 1.4em;
    }
    .input-text-area {
        font-size: 1.4em;
    }
    .contact-link-logo {
        height: 4em;
        margin: 0 2em;
    }
}