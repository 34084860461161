:root{
    --mainColor: #fefcbf;
    --secondaryColor: #2a6fdb;
    --tertiaryColor: #122c91;
    --mainTextColor: black;
    --secondaryTextColor: white;
    --mainTextBlockColor: rgba(255, 255, 255, .6);
}

* {
    font-family: 'Quicksand', sans-serif;
}
.app {
    text-align: center;
}

.navbar-spacer {
    padding: 0.8em 0;
    height: 2.5em;
}

.section-spacer {
    padding-bottom: 5em;
}

.section-title {
    font-family: 'Trispace', sans-serif;
    font-size: 2.5em;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

.sub-title-spacer{
    padding: 0.5em;
}

.sub-title {
    color: var(--mainColor);
    font-family: 'Trispace', sans-serif;
    font-size: 1em;
    text-align: center;
    margin-bottom: -1em;
    text-transform: uppercase;
}

/* Desktop */
@media only screen and (min-width: 768px) {
    .navbar-spacer {
        padding-top: 5em;
    }

    .section-spacer {
        padding-bottom: 9em;
    }

    .section-title {
        font-size: 3em;
    }

    .sub-title {
        font-size: 1.3em;
    }
}

@media only screen and (min-width: 1921px) {
    .navbar-spacer {
        padding-top: 8em;
    }

    .section-title {
        font-size: 5em;
    }

    .sub-title {
        font-size: 1.5em;
    }
}
